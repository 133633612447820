@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,600&family=Poppins:wght@700&display=swap');
.servicesclient_main-container{
    margin-top: 120px;
    margin-bottom: 120px;
}
.servicesclient_main-container .servicesclient_textcol h1{
font-size: 50px;
font-weight: 700;
font-family: 'Poppins', sans-serif;
margin-bottom: 40px;
}
.servicesclient_icon-col{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E3E3E3;
    padding: 6px !important;
   border-radius: 2px;
}
.servicesclient_icon{
    padding: 45px 0;
}
.servicesclient_icon-col:hover{
    box-shadow: 0px 0px 17px 9px #F8F8F8 ;
    transition: box-shadow 0.5s ease-in-out;
}
.servicesclient_icon .icon{
height:50px;
}
.servicesclient_icon .nirmata{
  height: 80px;
}
 .icon-col{
  padding: 55px 0;
 }
.servicesclient_icon .anzenna{
  height: 30px;
}
.servicesclient_icon .curriculum{
  height: 100px;
}

@media (max-width: 1000px) {
    .servicesclient_main-container .servicesclient_textcol h1 {
      margin-bottom: 40px;
    }
  }
  @media (max-width: 600px) {
    .servicesclient_main-container .servicesclient_textcol h1 {
      font-size: 35px;
      margin-bottom: 20px;
    }
  }
 @media(max-width:470px){
  .servicesclient_icon .armorcode{
    height: 42px;
  }
  .servicesclient_icon .anzenna{
    height: 28px;
  }
 }
 @media(max-width:400px){
  .servicesclient_icon{
    padding:35px 0;
  }
  .icon-col{
    padding:45px 0
  }
  .servicesclient_icon .nirmata{
    height: 73px;
  }
  .servicesclient_icon .armorcode{
    height: 38px;
  }
  .servicesclient_icon .anzenna{
    height:25px;
  }
  .servicesclient_icon .curriculum{
    height: 93px;
  }
 }