@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,600&display=swap');
.contactus_main-container{
    display: flex;
    padding:120px 0;
    font-family: 'Poppins', sans-serif;
    position: relative;
    justify-content: space-evenly;
}
.contactus_main-container h1{
    margin-top: 65px;
    font-weight: 600;
    font-size: 43px;
    max-width: 531px;
    margin-bottom: 1em;
}
.contactus_main-container .form-container{
    border: 2px solid #EEEDED;
    padding: 50px 60px;
    border-radius: 20px;
    box-shadow: 0px 39px 23px -15px #F5F5F5;
}
.contactus_main-container .form-container .formgroup1{
    display: flex;
}
.form-container .form-control{
    margin-bottom: 15px;
    border:none !important;
    border-bottom: 2px solid #E4E4E4!important;
    border-radius: 0 !important;
    margin-right: 50px;
}
.form-control::placeholder{
    color: #B1B4B7 !important;
}
.form-control:focus{
    box-shadow: none !important;
}
.surname{
    margin-right: 0 !important;
}
.contactbtn{
    padding:15px 32px;
    border-radius: 30px;
    border: none;
    font-size: 17px;
    font-weight: 500;
    color: white;
    background: linear-gradient(76deg,#421A6A,#C50049);
    margin-top: 20px;
    font-family: 'Nunito Sans', sans-serif;
}
.contactbtn:hover{
    background: linear-gradient(to right, #F0561C,#FEAA41);
}
.contactus-list-group{
    display: flex;
}
.contactus-list-group .list-group-item{
    border: none !important;
    margin-bottom: 20px;
    font-weight: 500;
}
.contactus-list-group .list-group-item span{
    margin-left: 20px;
}
@media (max-width:1000px){
    .contactus_main-container{
        padding: 88px 0 0 0;
        align-items: center;
        flex-direction: column;
    }
    .contactus_main-container h1{
        margin-top: 0;
        max-width: 600px;
    }
    .contactus_main-container .form-container{
        box-shadow: 9px 22px 23px 0px #F5F5F5;
    }

    .contactus-list-group{
         margin-right: 196px;
         position: static;
    }
}
@media (max-width:600px){
    .contactus_main-container h1{
        font-size: 31px;
        max-width: 389px;
    }
    .contactus-list-group{
        margin-right:0;
    }
    .contactus_main-container .form-container .formgroup1{
        flex-direction: column;
    }
    .contactus_main-container .form-container .formgroup2{
        display: flex;
        flex-direction: column;

    }

}