@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
#main_header-section{
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
}

.scroll .header_logo{
   height: 60px;
    transition: height 0.5s ease-in-out;
}
.navbar_header{
    background-color: #FCFAFC;
    font-family: 'Poppins', sans-serif;
    padding: 18px !important;
    transition: padding 0.5s ease-in-out;
}
.header_logo{
    height: 76px;
    transition: height 0.5s ease-in-out;
}

.scroll {
    padding:8px !important;
    transition: padding 0.5s ease-in-out;
}
.header_container{
    justify-content: space-between !important;
}
.nav_header{
    align-items: center;
}
.navbar_collapse{
    justify-content: flex-end;
}
.nav_header .nav-link{
    color:black !important;
    font-size: 20px;
    font-weight: 600;
}
.navbar-toggler{
    border:none !important; 
    font-size: 30px !important;
}
@media (max-width:600px){

   .header_logo{
    height:50px;
   }
   .navbar-toggler{
    font-size: 20px !important;
   }
   .scroll .header_logo{
    height: 40px;
   }
}