@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
.footer_main-container{
    display: flex;
    padding:0px 90px 0 90px !important;
    font-family: 'Poppins', sans-serif;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
}
/* .footer_second-container{
display: flex;
}
.footer_third-container p{
 max-width: 727px;
 font-size: 16px;
 line-height: 32px;
 color: #26282C;
 font-weight: 500;
}
.footer_third-container > img {
    margin-bottom: 1em;
}
.footer_fourth-container{
    display: flex;
    justify-content: space-evenly;
}
.footer_fourth-container .list-header{
    list-style: none !important;
    font-weight: 600;
}
.footer_fourth-container li{
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 15px;
}
.footer_fourth-container h4{
    margin-left: 15px;
    font-size:22px;
}
.footer_fourth-container li::marker{
    color: #CA195B;
}
.footer_fourth-container .third-list{
    margin-top: 35px;
} */
.footer_fifth-container{
    display: flex;
    justify-content: space-between;
    /* border-top: 1px solid #d1cece; */
    padding: 23px 0px;
    flex-wrap: wrap;
}
/* .footer_fifth-container img{
    margin-left: 15px;
} */

.footer_fifth-container a {
    padding: 0px;
    margin-left: 3px;
    margin-right: 9px;
}
/* @media (max-width:900px){

    
    .footer_third-container{
        flex-direction: column;
        align-items: center;
    }
    .footer_third-container p{
        max-width: 450px;
    }
} */
@media(max-width:800px){
    .footer_main-container{
        padding: 0px 33px 0 33px !important;
    }
    /* .footer_third-container p{
        max-width: 446px;
    } */
}
/* @media (max-width:600px){
    .footer_third-container{
        align-items: flex-start;
        margin-left: 20px;
    }
    .footer_fourth-container{
        flex-direction: column;
        padding:0 !important;
    }
    .footer_fourth-container li{
        font-size: 17px;
    }
    .footer_fourth-container .second-list{
        margin:0;
    }
    .footer_fourth-container .third-list{
        margin: 0;
    }
    .footer_second-container {
        flex-direction: column;
    }
    .footer_fourth-container {
        margin-top: 1em;
    }
} */
/* @media (max-width:500px){
    .footer_fifth-container{
        flex-direction: column-reverse;
        align-items: center;

    }
    .footer_fifth-container img{
        margin-bottom: 15px;
    }
} */