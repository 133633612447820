@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.home_main-container {
  font-family: "Poppins", sans-serif;
  background-repeat: no-repeat;
  background-size: 100% 780px;
  background-image: url("bg.png");
  padding: 261px 0px 86px 0;
}
.home_second-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  padding: 0 !important;
}
.main_text {
  font-size: 49px;
  font-weight: 700;
  line-height: 72px;
  max-width: 920px;
  padding-bottom: 20px;
}
.second_text {
  font-size: 22px;
  line-height: 42px;
  max-width: 549px;
  padding-bottom: 58px;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
@media (max-width: 900px) {
  .main_text {
    max-width: 775px;
    font-size: 38px;
  }
  .second_text {
    max-width: 499px;
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .main_text {
    font-size: 23px;
    line-height: 40px;
    max-width: 362px;
    padding: 0;
  }
  .second_text {
    font-size: 12px;
    line-height: 24px;
    max-width: 279px;
    padding: 0;
  }
  .home_main-container {
    background-size: 100% 550px;
    padding: 170px 0  57px 0;
  }
}
@media (max-width: 400px) {
  .main_text {
    font-size: 20px;
  }
  .second_text {
    font-size: 12px;
  }
}
