@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.services_main-container {
  font-family: "Poppins", sans-serif;
  padding: 120px 0px;
}
.services_second-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}
.services_second-container h5 {
  color: #c50049;
  font-weight: 500;
  font-size: 23px;
  margin-bottom: 5px;
}
.services_second-container h2 {
  font-weight: bold;
  font-size: 40px;
  color: #0e1b23;
  font-weight: 600;
  margin-bottom: 15px;
}
.services_second-container p {
  font-weight: 500;
  margin-bottom: 40px;
  font-size: 20px;
  color: #000000;
  max-width: 741px;
}

.icon_col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.services_third-container .servicesimage {
  height: 120px;
}
.icon-text {
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 600px) {
  .services_main-container{
   padding: 85px 0 0 0; 
  }
  .services_second-container h5 {
    font-size: 18px;
    
  }
  .services_second-container h2 {
    font-size: 32px;
    
  }
  .services_second-container p {
    font-size: 18px;
   
  }
}
