@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,600&display=swap');
.tech_main-container{
    display: flex;
    margin: 120px 0;
    --bs-gutter-x:0 !important;
    font-family: 'Poppins', sans-serif;
}
.tech_second-container{
        background-image: url("techdesign.png");
            background-repeat: no-repeat;
            background-size: 100% 514px;
            margin-top: 138px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: left;
            --bs-gutter-x:0 !important;

}
.tech_second-container::after{
    content: '';
    position: absolute;
    width: 574px;
    height: 546px;
    background: #C50049 0% 0% no-repeat padding-box;
    opacity: 0.1;
filter: blur(50px);
transform: translate(-47%,27%);
border-radius: 50%;
}
.tech_second-container h1{
    font-size: 45px;
    font-weight: 700;
    max-width: 411px;
    line-height: 58px;
}
.tech_second-container p{
    font-size: 19px;
    font-weight: 500;
    margin-top: 40px;
    max-width: 410px;
    line-height: 24px;
}
.tech_third-container{
    background-color: #F7F8F9;
    padding: 127px 85px 138px 85px !important;
}
.tech_third-container .row{
    --bs-gutter-x: 1rem !important;
    --bs-gutter-y: 1.5rem !important;
}
.tech_third-container .tech_icon{
display: flex;
flex-direction: column;
align-items: center;
background: white;
padding:26px 0px;
}
.tech_third-container .tech_icon:hover{
background:linear-gradient( 46deg, #421A6A, #C50049);
color: white;
transition:color 0.5s ease-in-out;
}

.tech_icon-text{
text-align: center;
margin-top: 25px;
font-size: 18px;
font-family: 'Nunito Sans', sans-serif;
font-weight: 600;
}
@media (max-width:1250px){
    .tech_main-container{
        flex-direction: column;
        margin: 80px;
    }
    .tech_second-container::after{
        display: none;
    }
    .tech_second-container{
        margin: 0;
        background:none !important;
        text-align: center;
    }
    .tech_second-container h1{
        max-width: 554px;
    }
    .tech_second-container p{
        margin-top: 20px
    }
    .tech_third-container{
        padding: 50px !important;
        margin: 0;
    }
    .tech_third-container .tech_icon{
        padding: 63px 0;
    }
}
@media (max-width:900px){
    .tech_third-container{
        padding:50px !important;
    }
}
@media (max-width:600px){
    .tech_second-container h1{
    font-size: 24px;
    line-height: 35px;
    }
    .tech_main-container p{
        font-size: 18px;
        margin-top: 5px;
        line-height: 28px;
    }
    .tech_third-container{
        padding: 30px !important;
    }
    .tech_third-container .tech_icon{
      padding: 23px 0;
    }
}
@media (max-width:400px){
    .tech_third-container{
        padding: 20px !important;
    }
}