@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.about_main-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins", sans-serif;
  padding: 80px 0px;
}
.about_second-container {
  display: flex;
  justify-content: center;
}
.aboutimage {
  height: 515px;
}
.about_third-container {
  margin-bottom: 70px;
}
.about_third-container h2 {
  font-weight: bold;
  text-align: left;
  font-size: 41px;
}
.about_text {
  margin-top: 35px;
  max-width: 530px;
}
.about_text p {
  font-size: 17px;
  line-height: 23px;
  font-family: "Roboto", sans-serif;
  color: #6a6e74;
}
@media (max-width:900px){
 .about_main-container{
  flex-direction: column;
  padding: 75px 0 0 0;
 }
 .about_third-container{
  margin: 21px 0 0 0;
 }
 .about_third-container h2{
  text-align: center;
 }
 .about_text {
  max-width: fit-content;
  margin-top: 13px;
 }

}
@media (max-width: 600px) {
  
  .aboutimage {
    height: 337px;
  }

  .about_third-container h2 {
    text-align: center;
    font-size: 32px;
  }
  .about_third-container p {
    font-size: 19px;
  }
}
@media(max-width:400px){
  .aboutimage{
    height: 280px;
  }
}
