@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&family=Poppins:wght@400;500;700&display=swap');
.servicespage3_main-container{
    font-family: 'Poppins', sans-serif;
    margin:120px 0;
}
.servicespage3_second-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
}
.servicespage3_second-container h1{
    font-size: 45px;
    line-height: 62px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    max-width: 835px;

}
.servicespage3_second-container p{
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    font-weight: 400;
    max-width: 856px;
    color: #414852;
}
.servicespage3_third-container{
    display: flex;
}
.servicespage3_third-container .servicespage3image{
    height:500px;
}
.servicespage3_third-container .servicespage3_row-container{
    margin-left: 133px;
    margin-top: 50px;
 }
 .servicespage3_third-container .servicespage3_row-container .row{
     --bs-gutter-y: 1.5rem !important; 
 }

.servicespage3_third-container h4{
    display: inline;
    margin-left: 30px;
    font-size: 24px;
    line-height: 33.6px;
    font-weight: 600;
}

.servicespage3_third-container p{
    margin-left: 70px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #414852;
    
}

@media (max-width:1200px){
    .servicespage3_third-container{
        flex-direction: column;
    }
    .servicespage3_third-container .servicespage3_row-container{
        margin-left: 0px;
         }
         .servicespage3_third-container .servicespage3image{
            height:600px;
        }

}
@media (max-width:1000px){
    .servicespage3_second-container h1{
        font-size: 40px;
        line-height: 50px;
    }
}
@media (max-width:800px){
    .servicespage3_second-container h1{
        font-size: 29px;
        line-height: 38px;
    }

}
@media (max-width:600px){
    .servicespage3_second-container h1{
        font-size: 24px;
     line-height: 30px;
    }
    .servicespage3_second-container p{
        font-size: 18px;
       line-height: 24px;

    }
   
    .servicespage3_third-container .servicespart3image{
        height:345px;
    }

     .servicespage3_third-container h4{
       margin: 10px 0 0 0;
     }
     .servicespage3_third-container p{
        margin: 20px 0 0 0;
     }
     .servicespage3_third-container .servicespage3_row-container .servicespage3-col{
        display: flex;
        flex-direction: column;
        align-items: flex-start;

     }
}
@media (max-width:400px){
    .servicespage3_second-container h1{
        font-size: 18px;
        line-height: 24px;
    }
    .servicespage3_second-container p{
        font-size: 15px;
        line-height: 24px;
    }
}